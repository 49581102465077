<template>
  <div class="pages">
    <div class="page">
      <div class="inside-nav">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :highlight-current="true"
          accordion
          @node-click="handleNodeClick"
        >
        </el-tree>
      </div>

      <div class="task_box">
        <div class="out">
          <div class="search">
            <div>
              <el-input placeholder="请输入内容" v-model="search">
                <i slot="prefix" class="el-input__icon el-icon-search"></i>
              </el-input>
            </div>
          </div>
          <div class="btns" @click="exportlist">
            <div class="el-icon el-icon-upload2"></div>
            <div>导&nbsp;出</div>
          </div>
        </div>
        <!-- select 筛选 -->
        <div class="select_box"></div>
        <div class="oper_box">
          <!-- <div class="s_item s_item_s">
            <div class="s_lable">数据周期：</div>
            <el-select v-model="selectValue" placeholder="请选择设备名称" @change="changeTime">
              <el-option
                v-for="item in numoptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                format="yyyy 年 MM 月 dd 日"
                value-format="yyyy-MM-dd"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="s_item s_item_s">
            <div class="s_lable">开始时间1：</div>
            <el-date-picker
              v-model="start"
              type="datetime"
              @change="timeChangeS(start)"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="s_item s_item_s">
            <div class="s_lable">结束时间：</div>
            <el-date-picker
              v-model="end"
              type="datetime"
              @change="timeChangeE(end)"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="btns" @click="submit">查询</div>
        </div>
        <div class="table">
          <table>
            <tr>
              <th>环境温度</th>
              <th>接头温度</th>
              <!-- <th>B点温度</th>
              <th>C点温度</th> -->
              <th>局放峰值</th>
              <th>局放均值</th>
              <th>局放频率次数</th>
              <th>水浸</th>
              <th>电池电压</th>
              <th>信号强度</th>
            </tr>
            <tr v-for="(item, index) in datelist" :key="index">
              <!-- <td>{{item.name}}</td> -->
              <th>{{ item.data.ambient_temperature }}</th>
              <th>{{ item.data.temperature }}</th>
              <!-- <th >{{item.temperature_b}}</th>
              <th >{{item.temperature_c}}</th> -->
              <!-- <th >{{item.data.partial_discharge}}</th> -->
              <th>{{ item.data.pv }}</th>
              <th>{{ item.data.mv }}</th>
              <th>{{ item.data.df }}</th>

              <th>{{ item.data.humidity }}</th>
              <!-- <th >{{item.data.vbat}}</th>
              <th >{{item.data.rssi}}</th> -->
              <td>3.65</td>
              <td>28</td>
            </tr>
          </table>
          <!-- 分页 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="total"
            :current-page="page"
            @current-change="handleCurrentChange"
            next-text="下一页"
            prev-text="上一页"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      numoptions: [
        {
          value: "days",
          label: "日",
        },
        {
          value: "month",
          label: "月",
        },
        {
          value: "year",
          label: "年",
        },
      ],
      selectValue: "",
      // 副 菜单
      radio: 1,
      // 树状图选择
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      // 参数
      start: "",
      end: "",
      search: "",
      //
      datelist: {},
      date: [],
      total: 0,
      page: 1,
      limit: 10,
    };
  },
  mounted: function () {
    this.getzuzhiList();
  },
  methods: {
    // 导出
    exportlist() {
      if (this.device_id == "") {
        this.$message.error("请选择组织");
        return;
      }
      if (this.screen == "") {
        this.$message.error("请选择时间");
        return;
      }
      if (this.cycle == "") {
        this.$message.error("请选择周期");
        return;
      }
      window.location.href =
        "http://211.149.176.35:9001/api/device/export_energy_usage?device_id=" +
        this.device_id +
        "&screen=" +
        this.screen +
        "&cycle=" +
        this.cycle;
    },
    async submit() {
      const res = await this.$http.post("device/cable_data", {
        start_time: this.start,
        end_time: this.end,
        name: this.search,
        page: this.page,
      });
      if (res.code !== 1) return this.$message.error(res.msg);
      this.$message.success(res.msg);
      this.date = res.data.date;
      this.datelist = res.data.list;
    },
    handleCurrentChange(newPage) {
      console.log(newPage, "newPage");
      this.page = newPage;
      this.submit();
    },
    // 周期下拉
    changeTime(e) {
      this.cycle = e;
    },
    // 时间选择
    timeChangeS(val) {
      // console.log(val);

      console.log(this.formatDate(val));
      this.start = this.formatDate(val);
      // const newDate = val.getFullYear() + '-' + (val.getMonth() + 1) + '-' + val.getDate();
      // this.start = newDate;
    },
    timeChangeE(val) {
      // console.log(val);
      this.end = this.formatDate(val);
      // const newDate = val.getFullYear() + '-' + (val.getMonth() + 1) + '-' + val.getDate();
      // this.end = newDate;
    },
    // 格式化函数
    formatDate(date) {
      let year = date.getFullYear(); // 获取年份
      let month = ("0" + (date.getMonth() + 1)).slice(-2); // 获取月份，并补零
      let day = ("0" + date.getDate()).slice(-2); // 获取日期，并补零
      let hours = ("0" + date.getHours()).slice(-2); // 获取小时，并补零
      let minutes = ("0" + date.getMinutes()).slice(-2); // 获取分钟，并补零
      let seconds = ("0" + date.getSeconds()).slice(-2); // 获取秒数，并补零

      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    // 组织 获取
    async getzuzhiList() {
      const res = await this.$http.post("Organize/left");
      this.treeData = res.data;
    },

    //获取选中的点
    handleCheckChange() {
      let res = this.$refs.tree.getCheckedNodes();
      let arr = [];
      res.forEach((item) => {
        if (!item.children) {
          arr.push(item.id);
        }
      });
      this.device_id = arr.join(",");
    },

    handleNodeClick(data) {
      console.log(data);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
  },
};
</script>
<style lang="scss" scoped>
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  background: rgba(35, 59, 103, 0.67);
  border-radius: 6px;
  border: 1px solid #4486ff;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background: rgba(35, 59, 103, 0.67);
  color: #ffffff;
  // overflow-x: auto;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node > .el-tree-node__children {
  overflow: visible;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}
.out {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .btns {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #ffffff;
    margin-left: 25px;
    background: #233b67;
    border-radius: 5px;
    border: 1px solid #4486ff;
    width: 70px;

    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    cursor: pointer;
    .el-icon {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}
// 表格
.task_box {
  width: calc(100% - 220px);
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #4486ff !important;
  }
  // charts
  .onecharts {
    width: 100%;
    height: 300px;
  }
  .twocharts {
    width: 100%;
    height: 300px;
  }
  .chartsTitle {
    font-size: 18px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
  }

  // 顶部按钮
  .elmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-menu.el-menu--horizontal {
      border: 1px solid #4486ff !important;
    }
    .el-menu.el-menu--horizontal {
      border: none !important;
    }
    .el-menu {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-menu--horizontal > .el-menu-item {
      color: #fff !important;
    }
    .el-menu-item.is-active {
      color: #68d8fe !important;
    }

    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-menu-item {
      border-left: 0px;
      margin-right: 20px;
    }
  }

  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    margin-bottom: 20px;

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      width: 100px;
      height: 40px;
      background: #233b67;
      border-radius: 6px;
      border: 1px solid #4486ff;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .s_item {
      display: flex;
      align-items: center;
      margin-left: 80px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  // select 筛选
  .index_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-tag {
      margin-right: 10px;
    }
  }
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      line-height: 50px;
      text-align: center;
      font-size: 16px;
      color: #ffffff;
      width: 100px;
      height: 50px;
      background: #233b67;
      border-radius: 6px;
      border: 1px solid #4486ff;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
.s_item_s {
  margin-right: 30px;
  margin-left: 0px !important;
}
.table {
  margin-top: 30px;
  height: 750px;
  overflow-y: auto;
  table,
  th,
  td {
    border: 1px solid #4486ff;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #e5e5e5;
    border-collapse: collapse;
  }
  table {
    width: 100%;

    border: 2px solid #4486ff;
  }
  th {
    height: 50px;
    width: 96px;
  }
  td {
    text-align: center;
    width: 180px;
    height: 50px;
  }
}
// 分页
::v-deep .el-pagination {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
::v-deep .el-pager li {
  font-size: 12px;
  border: 1px solid #61cdf1;
  font-weight: 500;
  color: #ffffff;
  background-color: #2c5092;
  padding: 0;
  height: 32px;
  line-height: 32px;
  min-width: 32px;
  margin: 0 7px;
}
::v-deep .btn-next,
::v-deep .btn-prev {
  border: 1px solid #61cdf1 !important;
  font-weight: 500 !important;
  color: #ffffff;
  background-color: #2c5092 !important;
  height: 32px !important;
  line-height: 32px !important;
  padding: 0 10px !important;
}
::v-deep .btn-next span,
.btn-prev span {
  font-size: 12px !important;
  height: 32px !important;
  line-height: 32px !important;
}
</style>
